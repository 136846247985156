export const columnAreaOptions = {
    chart: {
        stacked: false,
        toolbar: {
            show: false,
        },
    },
    stroke: {
        width: [0, 2, 3],
        curve: "smooth",
        dashArray: [0, 0, 5]
    },
    plotOptions: {
        bar: {
            columnWidth: "18%",
        },
    },
    colors: ["#fb4d53", "#0ab39c", "#299cdb"],
    fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
        },
    },
    labels: [
        "Gen", "Feb", "Mar", "Apr", "Mag", "Giu",
        "Lug", "Ago", "Set", "Ott", "Nov", "Dic"
    ],
    markers: {
        size: 0,
    },
    legend: {
        offsetY: 11,
    },
    xaxis: {
        type: "month",
    },
    yaxis: {
        title: {
            text: "Numero di Domini",
        },
    },
    tooltip: {
        shared: true,
        intersect: false,
        y: {
            formatter: function (y) {
                if (typeof y !== "undefined") {
                    return y.toFixed(0) + " domini"
                }
                return y
            },
        },
    },
    grid: {
        borderColor: "#f1f1f1",
    },
};
