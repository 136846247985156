import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axios from 'axios';

// Component styles and images
import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';
import {Card, CardBody, Col, Container, Row} from "reactstrap";

// Validation schema using Yup
const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Please enter your email"),
    name: Yup.string().required("Please enter your username"),
    password: Yup.string().required("Please enter your password")
});

const Register = () => {
    useEffect(() => {
        document.title = "Register | Upzet - React Admin & Dashboard Template";
    }, []);

    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
        try {
            const response = await axios.post('http://api-clean.test/api/register', values);
            alert('Registration successful!');
            resetForm();
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error("Backend returned status code: ", error.response.status);
                console.error("Error response data: ", error.response.data);
                setErrors({ api: error.response.data.message });
            } else if (error.request) {
                // The request was made but no response was received
                console.error("No response received: ", error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
            }
            setSubmitting(false);
        }
    };

    return (
        <div className="bg-pattern" style={{ height: "100vh" }}>
            <div className="bg-overlay"></div>
            <div className="account-pages pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6} md={8} xl={4}>
                            <Card className='mt-5'>
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <Link to="/">
                                            <img src={logodark} alt="" height="90" className="auth-logo logo-dark mx-auto mb-4" />
                                            <img src={logolight} alt="" height="24" className="auth-logo logo-light mx-auto" />
                                        </Link>
                                    </div>

                                    <h4 className="font-size-18 text-muted text-center mt-2">Free Register</h4>
                                    <p className="text-muted text-center mb-4">Get your free Upzet account now.</p>

                                    <Formik
                                        initialValues={{ email: '', name: '', password: '' }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting, errors }) => (
                                            <Form className="form-horizontal">
                                                {errors.api && <p className="alert alert-danger">{errors.api}</p>}
                                                <div className="form-group">
                                                    <label htmlFor="email" className="form-label">Email</label>
                                                    <Field name="email" type="email" className="form-control" />
                                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="name" className="form-label">Username</label>
                                                    <Field name="name" type="text" className="form-control" />
                                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="password" className="form-label">Password</label>
                                                    <Field name="password" type="password" className="form-control" />
                                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                </div>

                                                <div className="d-grid mt-4">
                                                    <button type="submit" className="btn btn-primary waves-effect waves-light" disabled={isSubmitting}>
                                                        Register
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p className="text-white-50">Already have an account ? <Link to="/login"
                                                                                             className="fw-medium text-primary"> Login </Link>
                                </p>
                                <p className="text-white-50">
                                    © {new Date().getFullYear()} Whiterady.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Register;
