import React from "react";
import { Container, Row, Col } from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DomainOverview from "./components/DomainOverview";
import RenewalNotifications from "./components/RenewalNotifications";
import QuickAccess from "./components/QuickAccess";
import LineColumnArea from "./components/LineColumnArea";
import EmailStatusDashboard from "./components/Emailqueue";

const Dashboard = () => {
    document.title = "Dashboard | Whiteready - Pannello di gestione domini";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Whiteready" breadcrumbItem="Dashboard" />
                    <Row>
                        <Col xl={4}>
                            <DomainOverview />
                        </Col>
                        <Col xl={4}>
                            <RenewalNotifications />
                        </Col>
                        <Col xl={4}>
                            <QuickAccess />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6}>
                            <LineColumnArea />
                        </Col>
                        <Col xl={6}>
                            <EmailStatusDashboard />
                        </Col>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;
