import { AlertTriangle, Check, Globe, XOctagon } from "lucide-react";
import mockApiResponse from '../../CommonData/mockApiResponse/mockApiDominiPanoramica.json';


// Mappa per convertire le stringhe delle icone in componenti React
const iconMap = {
    Globe: Globe,
    Check: Check,
    AlertTriangle: AlertTriangle,
    XOctagon: XOctagon
};

// Funzione per recuperare e formattare i dati
const fetchAndFormatOverviewData = async () => {
    try {
        // Simula una chiamata API
        const response = await new Promise(resolve => setTimeout(() => resolve(mockApiResponse), 500));

        if (response.status === "success") {
            return response.data.overview.map(item => ({
                ...item,
                icon: iconMap[item.icon]
            }));
        } else {
            throw new Error("Failed to fetch data");
        }
    } catch (error) {
        console.error("Error fetching overview data:", error);
        return [];
    }
};

// Esporta la funzione per recuperare i dati
export const OverviewData = fetchAndFormatOverviewData;


