import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { RefreshCw, ChevronDown, ChevronRight, X } from 'lucide-react';
import DataTable from 'react-data-table-component';
import apiCall from "../../../contexts/apiCall";

const EmailStatusDashboard = () => {
    const [emails, setEmails] = useState([]);
    const [filteredEmails, setFilteredEmails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeFilter, setActiveFilter] = useState(null);

    useEffect(() => {
        fetchEmails();
    }, []);

    useEffect(() => {
        if (activeFilter) {
            setFilteredEmails(emails.filter(email => email.status === activeFilter));
        } else {
            setFilteredEmails(emails);
        }
    }, [emails, activeFilter]);

    const fetchEmails = async () => {
        try {
            setLoading(true);
            const response = await apiCall.listEmailQueue();
            if (Array.isArray(response) && response.length > 0) {
                setEmails(response);
                setFilteredEmails(response);  // Mostra tutte le email di default
            } else {
                throw new Error("Dati non validi o vuoti");
            }
            setError(null);
        } catch (err) {
            console.error("Error fetching email status:", err);
            setError("Errore nel caricamento dei dati");
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Destinatario</span>,
            selector: row => row.recipient,
            sortable: true,
            width: '250px',
        },
        {
            name: <span className='font-weight-bold fs-13'>Stato</span>,
            sortable: true,
            width: '120px',
            cell: (row) => {
                switch (row.status) {
                    case "sent":
                        return <span className="badge bg-success">Inviata</span>;
                    case "failed":
                        return <span className="badge bg-danger">Fallita</span>;
                    case "pending":
                        return <span className="badge bg-warning">In attesa</span>;
                    default:
                        return <span className="badge bg-secondary">{row.status}</span>;
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Data di Invio</span>,
            selector: row => row.sent_at,
            sortable: true,
            width: '150px',
            cell: (row) => row.sent_at ? new Date(row.sent_at).toLocaleString() : 'Non inviata'
        },
    ];

    const ExpandedComponent = ({ data }) => (
        <div className="p-4">
            <p><strong>Oggetto:</strong> {data.subject}</p>
            <p><strong>Messaggio di Errore:</strong> {data.error_message || 'Nessun errore'}</p>
        </div>
    );

    const customStyles = {
        headRow: {
            style: {
                borderTop: '1px solid #e0e0e0',
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
            },
        },
    };

    const emailCounts = {
        sent: emails.filter(email => email.status === 'sent').length,
        pending: emails.filter(email => email.status === 'pending').length,
        failed: emails.filter(email => email.status === 'failed').length,
    };

    const handleFilterClick = (status) => {
        setActiveFilter(status === activeFilter ? null : status);
    };

    const resetFilter = () => {
        setActiveFilter(null);
    };

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                    <h4 className="card-title">Stato Email Inviate</h4>
                                    <div>
                                        <Button
                                            color="success"
                                            outline={activeFilter !== 'sent'}
                                            className="me-2"
                                            onClick={() => handleFilterClick('sent')}
                                        >
                                            Inviate: {emailCounts.sent}
                                        </Button>
                                        <Button
                                            color="warning"
                                            outline={activeFilter !== 'pending'}
                                            className="me-2"
                                            onClick={() => handleFilterClick('pending')}
                                        >
                                            In Attesa: {emailCounts.pending}
                                        </Button>
                                        <Button
                                            color="danger"
                                            outline={activeFilter !== 'failed'}
                                            className="me-2"
                                            onClick={() => handleFilterClick('failed')}
                                        >
                                            Fallite: {emailCounts.failed}
                                        </Button>
                                        {activeFilter && (
                                            <Button color="secondary" className="me-2" onClick={resetFilter}>
                                                <X size={14} className="me-1" />Reset Filtro
                                            </Button>
                                        )}
                                        <Button color="primary" onClick={fetchEmails}>
                                            <RefreshCw className="me-2" size={14} />Aggiorna
                                        </Button>
                                    </div>
                                </div>
                                {error && (
                                    <div className="alert alert-danger" role="alert">
                                        {error}
                                    </div>
                                )}
                                <div>
                                    <DataTable
                                        columns={columns}
                                        data={filteredEmails}
                                        progressPending={loading}
                                        progressComponent={<div className="text-center my-3">Caricamento...</div>}
                                        noDataComponent={<div className="text-center my-3">Nessun dato disponibile</div>}
                                        customStyles={customStyles}
                                        fixedHeader
                                        fixedHeaderScrollHeight="400px"
                                        highlightOnHover
                                        pointerOnHover
                                        pagination={false}
                                        dense
                                        expandableRows
                                        expandableRowsComponent={ExpandedComponent}
                                        expandableIcon={{
                                            collapsed: <ChevronRight size={20} />,
                                            expanded: <ChevronDown size={20} />,
                                        }}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default EmailStatusDashboard;
