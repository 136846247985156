import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// i18n
import { useTranslation } from "react-i18next";
// Redux
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = () => {
  const [menu, setMenu] = useState(false);
  const [username, setUsername] = useState("Admin");
  const { t } = useTranslation();

  const profileState = useSelector(state => state.profile);
  const success = profileState ? profileState.success : false;

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    if (authUser) {
      try {
        const obj = JSON.parse(authUser);
        setUsername(obj.name || "Admin");
      } catch (error) {
        console.error("Error parsing authUser:", error);
        setUsername("Admin");
      }
    }
  }, [success]);

  return (
      <React.Fragment>
        <Dropdown
            isOpen={menu}
            toggle={() => setMenu(!menu)}
            className="d-inline-block"
        >
          <DropdownToggle
              className="btn header-item "
              id="page-header-user-dropdown"
              tag="button"
          >
            <img
                className="rounded-circle header-profile-user"
                src={user1}
                alt="Header Avatar"
            />
            <span className="d-none d-xl-inline-block ms-2 me-2">{username}</span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem tag="a" href="/userprofile">
              <i className="ri-user-line align-middle me-2" />
              {t("Profile")}
            </DropdownItem>
            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="ri-shut-down-line align-middle me-2 text-danger" />
              <span>{t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

export default ProfileMenu;
