import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const AuthProtected = ({ children }) => {
  const isAuthenticated = () => {
    // Controlla se esiste un token nel localStorage
    const token = localStorage.getItem('token');
    return !!token; // Restituisce true se il token esiste, false altrimenti
  };

  if (!isAuthenticated()) {
    // Se l'utente non è autenticato, reindirizza alla pagina di login
    return <Navigate to="/login" replace />;
  }

  // Se l'utente è autenticato, renderizza i componenti figli
  return <>{children}</>;
};

AuthProtected.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthProtected };
