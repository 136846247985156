import mockApiResponseEventoCalendario from "../mockApiResponse/mockApiCalendarEventsResponse.json";

 const CalendarioEventi = async () => {

    try {
        // Simuliamo una chiamata API
        const response = await new Promise(resolve => setTimeout(() => resolve(mockApiResponseEventoCalendario), 500));

        // Verifichiamo che la risposta sia valida
        if (response.status === 'success') {
            return response.data.events;
        } else {
            throw new Error('Formato dati non valido');
        }
    } catch (error) {
        console.error('Errore nel recupero delle notifiche di rinnovo:', error);
        return []; // Restituiamo un array vuoto in caso di errore
    }
};

export const EventsCalendar = CalendarioEventi;



