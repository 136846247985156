import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Progress, Spinner, Alert } from "reactstrap";
import { OverviewData } from "../../../CommonData/Dashboard/OverviewData";

const DomainOverview = () => {
    const [stats, setStats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await OverviewData();
                if (Array.isArray(data) && data.length > 0) {
                    setStats(data);
                } else {
                    throw new Error("Dati non validi o vuoti");
                }
                setError(null);
            } catch (err) {
                console.error("Error fetching overview data:", err);
                setError("Errore nel caricamento dei dati");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const renderContent = () => {
        if (loading) {
            return (
                <div className="text-center" style={{ minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                        <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
                        <p className="mt-2">Caricamento in corso...</p>
                    </div>
                </div>
            );
        }

        if (error || stats.length === 0) {
            return (

            <Alert color="danger">
                <h4 className="card-title mb-4">Panoramica dei Domini</h4>
                Errore nel caricamento dei dati. Si prega di contattare l'amministratore o riprovare più tardi.
                </Alert>
            );
        }

        return (
            <>
                <h4 className="card-title mb-4">Panoramica dei Domini</h4>
                <Row>
                    {stats.map((stat, index) => (
                        <Col md={3} sm={6} key={index}>
                            <div className="text-center mb-4">
                                <div className={`avatar-sm mx-auto mb-3 rounded-circle bg-${stat.color}-subtle`}>
                                    <span className={`avatar-title rounded-circle bg-${stat.color}-subtle text-${stat.color} font-size-18`}>
                                        {stat.icon && <stat.icon size={18} />}
                                    </span>
                                </div>
                                <h5 className="font-size-14">{stat.title}</h5>
                                <p className="text-muted mb-0">{stat.count}</p>
                            </div>
                        </Col>
                    ))}
                </Row>
                <div className="table-responsive mt-4">
                    <table className="table table-hover table-nowrap mb-0">
                        <thead className="table-light">
                        <tr>
                            <th scope="col">Stato</th>
                            <th scope="col">Conteggio</th>
                            <th scope="col">Percentuale</th>
                            <th scope="col">Progresso</th>
                        </tr>
                        </thead>
                        <tbody>
                        {stats.slice(1).map((stat, index) => (
                            <tr key={index}>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div className={`avatar-xs me-3 bg-${stat.color}-subtle rounded`}>
                                                <span className={`avatar-title rounded-circle bg-${stat.color}-subtle text-${stat.color} font-size-18`}>
                                                    {stat.icon && <stat.icon size={14} />}
                                                </span>
                                        </div>
                                        <span>{stat.title}</span>
                                    </div>
                                </td>
                                <td>{stat.count}</td>
                                <td>{stat.percentage?.toFixed(1)}%</td>
                                <td style={{width: "30%"}}>
                                    <Progress
                                        value={stat.percentage}
                                        color={stat.color}
                                        style={{height: "5px"}}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };

    return (
        <Card>
            <CardBody>
                {renderContent()}
            </CardBody>
        </Card>
    );
};

export default DomainOverview;
