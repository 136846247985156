import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";
import Domini from "../Pages/Domini/Nuovi";
import DomainManagement from "../Pages/Domini/Esistenti";
import ListaClienti from "../Pages/Clienti/Lista";
import NuovoCliente from "../Pages/Clienti/Nuovo";
import DomainCalendar from "../Pages/Calender";

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },


  // Profile
  { path: "/userprofile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  {
    path: "/domini",
    exact: true,
    component: <Domini />,
  },
    {
    path: "/calendario",
    exact: true,
    component: <DomainCalendar />,
  },

  {
    path: "/manage-domini",
    exact: true,
    component: <DomainManagement />,
  },
  {
    path: "/lista-clienti",
    exact: true,
    component: <ListaClienti />,
  },
  {
    path: "/nuovo-cliente",
    exact: true,
    component: <NuovoCliente />,
  },
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
