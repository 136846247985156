import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Card, CardBody, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import DataTable from 'react-data-table-component';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { MoreVertical, Edit, Trash, Mail } from 'lucide-react';
import { fakeCustomers } from '../../../CommonData/Data/ClientiData';

const ClientTable = () => {
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        setCustomers(fakeCustomers);
    }, []);

    const columns = [
        {
            name: 'Nome',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Telefono',
            selector: row => row.phone,
            sortable: true,
        },
        {
            name: 'Stato',
            selector: row => row.status,
            sortable: true,
            cell: row => (
                <Badge color={row.status === 'Attivo' ? 'success' : 'danger'} pill>
                    {row.status}
                </Badge>
            ),
        },
        {
            name: 'Data Registrazione',
            selector: row => row.registrationDate,
            sortable: true,
        },
        {
            name: 'Azioni',
            cell: row => (
                <UncontrolledDropdown>
                    <DropdownToggle tag="button" className="btn btn-soft-secondary btn-sm">
                        <MoreVertical size={16} />
                    </DropdownToggle>
                    <DropdownMenu end>
                        <DropdownItem href="#"><Edit size={14} className="me-2" /> Modifica</DropdownItem>
                        <DropdownItem href="#"><Mail size={14} className="me-2" /> Invia Email</DropdownItem>
                        <DropdownItem href="#" className="text-danger"><Trash size={14} className="me-2" /> Elimina</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            ),
        },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Clienti" breadcrumbItem="Gestione Clienti" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">I tuoi clienti</h4>
                                    <DataTable
                                        columns={columns}
                                        data={customers}
                                        pagination
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ClientTable;
