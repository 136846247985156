// Importiamo la funzione di mock API che abbiamo creato
import mockApiResponseNotifica  from '../../CommonData/mockApiResponse/mockRenewalNotificationsApi.json';

export const notificheRinnDomini = async () => {
    try {
        // Simuliamo una chiamata API
        const response = await new Promise(resolve => setTimeout(() => resolve(mockApiResponseNotifica), 500));

        // Verifichiamo che la risposta sia valida
        if (response.status === 'success' && Array.isArray(response.data.notifications)) {
            return response.data.notifications;
        } else {
            throw new Error('Formato dati non valido');
        }
    } catch (error) {
        console.error('Errore nel recupero delle notifiche di rinnovo:', error);
        return []; // Restituiamo un array vuoto in caso di errore
    }
};

export const NotificationsData = notificheRinnDomini;
