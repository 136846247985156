import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import { User, Mail, Phone, Building, MapPin, FileText } from 'lucide-react';
import Breadcrumbs from '../../../components/Common/Breadcrumb';

const ClientRegistration = () => {
    const [client, setClient] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        address: '',
        city: '',
        country: '',
        postalCode: '',
        taxId: '',
        notes: ''
    });

    const [submitStatus, setSubmitStatus] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setClient(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitStatus(null);

        try {
            // Simulazione di una chiamata API
            const response = await new Promise(resolve => setTimeout(() => resolve({
                ok: true,
                json: () => Promise.resolve({ success: true, message: 'Cliente registrato con successo' })
            }), 1000));

            if (response.ok) {
                const data = await response.json();
                setSubmitStatus({ type: 'success', message: data.message });
                // Reset del form dopo il successo
                setClient({
                    name: '',
                    email: '',
                    phone: '',
                    company: '',
                    address: '',
                    city: '',
                    country: '',
                    postalCode: '',
                    taxId: '',
                    notes: ''
                });
            } else {
                throw new Error('Errore nella registrazione del cliente');
            }
        } catch (err) {
            setSubmitStatus({ type: 'danger', message: 'Si è verificato un errore durante la registrazione. Riprova più tardi.' });
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Clienti" breadcrumbItem="Inserimento Anagrafica" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Inserimento Nuovo Cliente</h4>
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="name">Nome Completo</Label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><User size={18} /></span>
                                                        <Input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            placeholder="Inserisci il nome completo"
                                                            value={client.name}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="email">Email</Label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><Mail size={18} /></span>
                                                        <Input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            placeholder="Inserisci l'indirizzo email"
                                                            value={client.email}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="phone">Telefono</Label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><Phone size={18} /></span>
                                                        <Input
                                                            type="tel"
                                                            name="phone"
                                                            id="phone"
                                                            placeholder="Inserisci il numero di telefono"
                                                            value={client.phone}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="company">Azienda</Label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><Building size={18} /></span>
                                                        <Input
                                                            type="text"
                                                            name="company"
                                                            id="company"
                                                            placeholder="Inserisci il nome dell'azienda"
                                                            value={client.company}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <Label for="address">Indirizzo</Label>
                                            <div className="input-group">
                                                <span className="input-group-text"><MapPin size={18} /></span>
                                                <Input
                                                    type="text"
                                                    name="address"
                                                    id="address"
                                                    placeholder="Inserisci l'indirizzo"
                                                    value={client.address}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </FormGroup>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="city">Città</Label>
                                                    <Input
                                                        type="text"
                                                        name="city"
                                                        id="city"
                                                        placeholder="Inserisci la città"
                                                        value={client.city}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="country">Paese</Label>
                                                    <Input
                                                        type="text"
                                                        name="country"
                                                        id="country"
                                                        placeholder="Inserisci il paese"
                                                        value={client.country}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="postalCode">CAP</Label>
                                                    <Input
                                                        type="text"
                                                        name="postalCode"
                                                        id="postalCode"
                                                        placeholder="Inserisci il CAP"
                                                        value={client.postalCode}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <Label for="taxId">Partita IVA / Codice Fiscale</Label>
                                            <div className="input-group">
                                                <span className="input-group-text"><FileText size={18} /></span>
                                                <Input
                                                    type="text"
                                                    name="taxId"
                                                    id="taxId"
                                                    placeholder="Inserisci la Partita IVA o il Codice Fiscale"
                                                    value={client.taxId}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="notes">Note</Label>
                                            <Input
                                                type="textarea"
                                                name="notes"
                                                id="notes"
                                                rows="3"
                                                placeholder="Inserisci eventuali note aggiuntive"
                                                value={client.notes}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <Button color="primary" type="submit">
                                            Registra Cliente
                                        </Button>
                                    </Form>

                                    {submitStatus && (
                                        <Alert color={submitStatus.type} className="mt-4">
                                            {submitStatus.message}
                                        </Alert>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ClientRegistration;
