import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";  // Assicurati che il percorso sia corretto

const Logout = () => {
  const { logout } = useAuth();
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  useEffect(() => {
    const performLogout = async () => {
      await logout();
      setIsLoggedOut(true);
    };

    performLogout();
  }, [logout]);

  if (isLoggedOut) {
    return <Navigate to="/login" />;
  }

  return null;  // Invece di un frammento vuoto, usiamo null
};

export default Logout;
