import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { PlusCircle, RefreshCw, Settings } from 'lucide-react';
import { Link } from 'react-router-dom';

const QuickAccessItem = ({ icon: Icon, title, color, link }) => (
    <Col xs={4}>
        <div className="quick-access-item text-center">
            <div className={`avatar-md mx-auto mb-4 d-flex align-items-center justify-content-center rounded-circle bg-light`}>
                <Icon size={24} className={`text-${color}`} />
            </div>
            <h5 className="font-size-15 mb-1">{title}</h5>
            <Link to={link} className={`text-${color} font-size-13`}>Accedi</Link>
        </div>
    </Col>
);

const QuickAccess = () => {
    const quickAccessItems = [
        {
            icon: PlusCircle,
            title: "Registra dominio",
            color: "primary",
            link: "/domini"
        },
        {
            icon: RefreshCw,
            title: "Rinnova domini",
            color: "success",
            link: "/manage-domini"
        },
        {
            icon: Settings,
            title: "Gestisci DNS",
            color: "warning",
            link: "/manage-domini"
        },
    ];

    return (
        <Card>
            <CardBody>
                <h4 className="card-title mb-4">Accesso Rapido</h4>
                <Row>
                    {quickAccessItems.map((item, index) => (
                        <QuickAccessItem key={index} {...item} />
                    ))}
                </Row>
            </CardBody>
        </Card>
    );
};

export default QuickAccess;
