const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        isHasArrow: true,
        url: "/dashboard"
    },
    {
        label: "Calendario",
        icon: "mdi mdi-calendar",
        isHasArrow: true,
        url: "/calendario"
    },
    {
        label: "Domini",
        icon: "mdi mdi-web",
        subItem: [
            { sublabel: "Nuovo dominio", link: "/domini", icon: "mdi mdi-plus-circle-outline" },
            { sublabel: "Domini Esistenti", link: "/manage-domini", icon: "mdi mdi-dns" },
        ],
    },
    {
        label: "Clienti",
        icon: "mdi mdi-account-group",
        subItem: [
            { sublabel: "Lista Clienti", link: "/lista-clienti", icon: "mdi mdi-plus-circle-outline" },
            { sublabel: "Nuovo Cliente", link: "/nuovo-cliente", icon: "mdi mdi-dns" },
        ],
    },
]
export default SidebarData;
