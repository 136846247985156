import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    Spinner,
    Alert
} from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import itLocale from '@fullcalendar/core/locales/it';

import Breadcrumbs from "../../components/Common/Breadcrumb";

// Importa i dati degli eventi per il calendario
import { EventsCalendar } from "../../CommonData/Data/CalendarData";

const DomainCalendar = () => {
    const [events, setEvents] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const toggle = () => setModal(!modal);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await EventsCalendar();
                if (Array.isArray(data) && data.length > 0) {
                    setEvents(data);
                } else {
                    throw new Error("Dati non validi o vuoti");
                }
                setError(null);
            } catch (err) {
                console.error("Error fetching calendar data:", err);
                setError("Errore nel caricamento dei dati del calendario.Contattare l'amministratore.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleEventClick = (clickInfo) => {
        setSelectedEvent(clickInfo.event);
        toggle();
    };

    const handleEventDrop = (dropInfo) => {
        const { event } = dropInfo;
        setEvents(prevEvents =>
            prevEvents.map(ev =>
                ev.id === event.id ? { ...ev, start: event.start, end: event.end } : ev
            )
        );
    };

    const handleDateSelect = (selectInfo) => {
        const { start } = selectInfo;
        setSelectedEvent({ start, end: start });
        toggle();
    };

    const handleEventAdd = (values) => {
        const newEvent = {
            id: String(events.length + 1),
            title: values.title,
            start: selectedEvent.start,
            className: `bg-${values.type}`
        };
        setEvents([...events, newEvent]);
        toggle();
    };

    const handleEventUpdate = (values) => {
        setEvents(prevEvents =>
            prevEvents.map(ev =>
                ev.id === selectedEvent.id
                    ? { ...ev, title: values.title, className: `bg-${values.type}` }
                    : ev
            )
        );
        toggle();
    };

    const handleEventDelete = () => {
        setEvents(prevEvents => prevEvents.filter(ev => ev.id !== selectedEvent.id));
        toggle();
    };

    const renderEventContent = (eventInfo) => {
        return (
            <>
                <b>{eventInfo.timeText}</b>
                <i>{eventInfo.event.title}</i>
            </>
        );
    };

    const renderContent = () => {
        if (loading) {
            return (
                <div className="text-center" style={{ minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
                </div>
            );
        }

        if (error || events.length === 0) {
            return (
                <Alert color="danger" className="text-center" style={{ minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {error || "Nessun evento disponibile nel calendario"}
                </Alert>
            );
        }

        return (
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, bootstrap5Plugin]}
                initialView="dayGridMonth"
                themeSystem="bootstrap"
                locale={itLocale}
                headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                }}
                buttonText={{
                    today: 'Oggi',
                    month: 'Mese',
                    week: 'Settimana',
                    day: 'Giorno',
                    list: 'Lista'
                }}
                editable={true}
                droppable={true}
                selectable={true}
                events={events}
                eventContent={renderEventContent}
                eventClick={handleEventClick}
                eventDrop={handleEventDrop}
                select={handleDateSelect}
            />
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Gestore Domini" breadcrumbItem="Calendario Scadenze"/>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    {renderContent()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    {selectedEvent && selectedEvent.id ? "Modifica Evento" : "Aggiungi Evento"}
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        const formData = new FormData(e.target);
                        const values = Object.fromEntries(formData);
                        if (selectedEvent && selectedEvent.id) {
                            handleEventUpdate(values);
                        } else {
                            handleEventAdd(values);
                        }
                    }}>
                        <FormGroup>
                            <Label for="eventTitle">Titolo</Label>
                            <Input
                                type="text"
                                name="title"
                                id="eventTitle"
                                required
                                defaultValue={selectedEvent ? selectedEvent.title : ""}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="eventType">Tipo</Label>
                            <Input type="select" name="type" id="eventType">
                                <option value="danger">Scadenza</option>
                                <option value="success">Rinnovo</option>
                                <option value="primary">Registrazione</option>
                            </Input>
                        </FormGroup>
                        <Button color="primary" type="submit">
                            {selectedEvent && selectedEvent.id ? "Aggiorna" : "Aggiungi"}
                        </Button>
                        {selectedEvent && selectedEvent.id && (
                            <Button color="danger" className="ml-2" onClick={handleEventDelete}>
                                Elimina
                            </Button>
                        )}
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default DomainCalendar;
