import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { RefreshCw, Edit, Send } from 'lucide-react';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';

import {ListaDomini} from "../../../CommonData/Data/ListaDominiData";

const DomainManagement = () => {
    const [domains, setDomains] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');

    useEffect(() => {
        setDomains(ListaDomini);
    }, []);

    const handleAction = (domain, type) => {
        setSelectedDomain(domain);
        setModalType(type);
        setModalOpen(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Qui implementare la logica per gestire l'azione (rinnovo, trasferimento, modifica)
        console.log(`Performing ${modalType} for domain:`, selectedDomain);
        setModalOpen(false);
    };

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Nome Dominio</span>,
            selector: row => row.name,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Data di Scadenza</span>,
            selector: row => row.expirationDate,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Stato</span>,
            sortable: true,
            cell: (row) => {
                switch (row.status) {
                    case "Attivo":
                        return <span className="badge bg-success"> {row.status} </span>;
                    case "In scadenza":
                        return <span className="badge bg-warning"> {row.status} </span>;
                    case "Scaduto":
                        return <span className="badge bg-danger"> {row.status} </span>;
                    default:
                        return <span className="badge badge-soft-secondary"> {row.status} </span>;
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Registrar</span>,
            selector: row => row.registrar,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Nameservers</span>,
            selector: row => row.nameservers,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Azioni</span>,
            cell: (row) => {
                return (
                    <UncontrolledDropdown className="dropdown d-inline-block">
                        <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                            <i className="ri-more-fill align-middle"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem onClick={() => handleAction(row, 'renew')}><RefreshCw className="me-2" size={14} />Rinnova</DropdownItem>
                            <DropdownItem onClick={() => handleAction(row, 'transfer')}><Send className="me-2" size={14} />Trasferisci</DropdownItem>
                            <DropdownItem onClick={() => handleAction(row, 'edit')}><Edit className="me-2" size={14} />Modifica</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
            },
        },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Domini" breadcrumbItem="Gestione Domini" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">I tuoi domini</h4>
                                    <DataTable
                                        columns={columns}
                                        data={domains}
                                        pagination
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
                    {modalType === 'renew' && 'Rinnova Dominio'}
                    {modalType === 'transfer' && 'Trasferisci Dominio'}
                    {modalType === 'edit' && 'Modifica Dominio'}
                </ModalHeader>
                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        {selectedDomain && (
                            <FormGroup>
                                <Label for="domainName">Nome Dominio</Label>
                                <Input type="text" name="domainName" id="domainName" value={selectedDomain.name} readOnly />
                            </FormGroup>
                        )}
                        {modalType === 'renew' && (
                            <FormGroup>
                                <Label for="renewalPeriod">Periodo di Rinnovo</Label>
                                <Input type="select" name="renewalPeriod" id="renewalPeriod">
                                    <option>1 anno</option>
                                    <option>2 anni</option>
                                    <option>5 anni</option>
                                </Input>
                            </FormGroup>
                        )}
                        {modalType === 'transfer' && (
                            <FormGroup>
                                <Label for="authCode">Codice di Autorizzazione</Label>
                                <Input type="text" name="authCode" id="authCode" placeholder="Inserisci il codice di autorizzazione" />
                            </FormGroup>
                        )}
                        {modalType === 'edit' && (
                            <FormGroup>
                                <Label for="nameservers">Nameservers</Label>
                                <Input type="text" name="nameservers" id="nameservers" placeholder="ns1.example.com, ns2.example.com" />
                            </FormGroup>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setModalOpen(false)}>Annulla</Button>
                        <Button color="primary" type="submit">Conferma</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default DomainManagement;
