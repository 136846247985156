import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Table, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Search, Check, X, UserPlus, User } from 'lucide-react';
import Breadcrumbs from '../../../components/Common/Breadcrumb';

const DomainSearchRegistration = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [registrationStatus, setRegistrationStatus] = useState(null);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [clientModalOpen, setClientModalOpen] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [newClient, setNewClient] = useState({ name: '', email: '', phone: '' });

    useEffect(() => {
        // Simulazione del caricamento della lista clienti
        setClientList([
            { id: 1, name: 'Mario Rossi', email: 'mario@example.com', phone: '1234567890' },
            { id: 2, name: 'Giulia Bianchi', email: 'giulia@example.com', phone: '0987654321' },
        ]);
    }, []);

    const handleSearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSearchResults([]);

        try {
            // Simulazione di una chiamata API
            const response = await new Promise(resolve => setTimeout(() => resolve({
                ok: true,
                json: () => Promise.resolve([
                    { domain: `${searchTerm}.com`, available: true, price: 10.99 },
                    { domain: `${searchTerm}.org`, available: true, price: 12.99 },
                    { domain: `${searchTerm}.net`, available: false, price: null },
                ])
            }), 1000));

            if (response.ok) {
                const data = await response.json();
                setSearchResults(data);
            } else {
                throw new Error('Errore nella ricerca del dominio');
            }
        } catch (err) {
            setError('Si è verificato un errore durante la ricerca. Riprova più tardi.');
        } finally {
            setLoading(false);
        }
    };

    const handleClientSelection = (client) => {
        setSelectedClient(client);
        setClientModalOpen(false);
        proceedWithRegistration();
    };

    const handleNewClientSubmit = () => {
        // Simulazione dell'aggiunta di un nuovo cliente
        const newClientWithId = { ...newClient, id: Date.now() };
        setClientList([...clientList, newClientWithId]);
        setSelectedClient(newClientWithId);
        setClientModalOpen(false);
        proceedWithRegistration();
    };

    const proceedWithRegistration = async () => {
        setLoading(true);
        setError('');
        setRegistrationStatus(null);

        try {
            // Simulazione di una chiamata API per la registrazione
            const response = await new Promise(resolve => setTimeout(() => resolve({
                ok: true,
                json: () => Promise.resolve({ success: true, message: 'Dominio registrato con successo' })
            }), 1500));

            if (response.ok) {
                const data = await response.json();
                setRegistrationStatus({ success: true, message: `${data.message} per il cliente ${selectedClient.name}` });
            } else {
                throw new Error('Errore nella registrazione del dominio');
            }
        } catch (err) {
            setRegistrationStatus({ success: false, message: 'Si è verificato un errore durante la registrazione. Riprova più tardi.' });
        } finally {
            setLoading(false);
            setSelectedDomain(null);
            setSelectedClient(null);
        }
    };

    const initiateRegistration = (domain) => {
        setSelectedDomain(domain);
        setClientModalOpen(true);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Domini" breadcrumbItem="Ricerca e Registrazione" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Cerca e Registra il tuo Dominio</h4>
                                    <Form onSubmit={handleSearch}>
                                        <FormGroup>
                                            <Label for="domainSearch">Nome del Dominio</Label>
                                            <div className="input-group">
                                                <Input
                                                    type="text"
                                                    id="domainSearch"
                                                    placeholder="Inserisci il nome del dominio desiderato"
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                    required
                                                />
                                                <Button color="primary" type="submit" disabled={loading}>
                                                    {loading ? 'Ricerca...' : <><Search size={18} /> Cerca</>}
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </Form>

                                    {error && <Alert color="danger">{error}</Alert>}

                                    {searchResults.length > 0 && (
                                        <Table className="table-centered table-nowrap mb-0 mt-4">
                                            <thead className="thead-light">
                                            <tr>
                                                <th>Dominio</th>
                                                <th>Disponibilità</th>
                                                <th>Prezzo</th>
                                                <th>Azione</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {searchResults.map((result, index) => (
                                                <tr key={index}>
                                                    <td>{result.domain}</td>
                                                    <td>
                                                        {result.available ? (
                                                            <span className="badge bg-success">Disponibile</span>
                                                        ) : (
                                                            <span className="badge bg-danger">Non Disponibile</span>
                                                        )}
                                                    </td>
                                                    <td>{result.available ? `€${result.price}` : '-'}</td>
                                                    <td>
                                                        {result.available && (
                                                            <Button
                                                                color="primary"
                                                                size="sm"
                                                                onClick={() => initiateRegistration(result.domain)}
                                                                disabled={loading}
                                                            >
                                                                Registra
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    )}

                                    {registrationStatus && (
                                        <Alert
                                            color={registrationStatus.success ? 'success' : 'danger'}
                                            className="mt-4"
                                        >
                                            {registrationStatus.message}
                                        </Alert>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={clientModalOpen} toggle={() => setClientModalOpen(!clientModalOpen)} size="lg">
                <ModalHeader toggle={() => setClientModalOpen(!clientModalOpen)}>
                    Seleziona o Crea Cliente
                </ModalHeader>
                <ModalBody>
                    <h5>Clienti Esistenti</h5>
                    <Table>
                        <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Telefono</th>
                            <th>Azione</th>
                        </tr>
                        </thead>
                        <tbody>
                        {clientList.map((client) => (
                            <tr key={client.id}>
                                <td>{client.name}</td>
                                <td>{client.email}</td>
                                <td>{client.phone}</td>
                                <td>
                                    <Button color="primary" size="sm" onClick={() => handleClientSelection(client)}>
                                        <User size={14} /> Seleziona
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                    <h5 className="mt-4">Nuovo Cliente</h5>
                    <Form>
                        <FormGroup>
                            <Label for="clientName">Nome</Label>
                            <Input
                                type="text"
                                name="clientName"
                                id="clientName"
                                value={newClient.name}
                                onChange={(e) => setNewClient({...newClient, name: e.target.value})}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="clientEmail">Email</Label>
                            <Input
                                type="email"
                                name="clientEmail"
                                id="clientEmail"
                                value={newClient.email}
                                onChange={(e) => setNewClient({...newClient, email: e.target.value})}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="clientPhone">Telefono</Label>
                            <Input
                                type="tel"
                                name="clientPhone"
                                id="clientPhone"
                                value={newClient.phone}
                                onChange={(e) => setNewClient({...newClient, phone: e.target.value})}
                            />
                        </FormGroup>
                        <Button color="success" onClick={handleNewClientSubmit}>
                            <UserPlus size={14} /> Crea e Seleziona
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default DomainSearchRegistration;
