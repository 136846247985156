

export const fakeCustomers = [
    { id: 1, name: "Mario Rossi", email: "mario.rossi@email.com", phone: "+39 123 456 7890", status: "Attivo", registrationDate: "2023-01-15" },
    { id: 2, name: "Giulia Bianchi", email: "giulia.bianchi@email.com", phone: "+39 234 567 8901", status: "Inattivo", registrationDate: "2023-02-20" },
    { id: 3, name: "Luca Verdi", email: "luca.verdi@email.com", phone: "+39 345 678 9012", status: "Attivo", registrationDate: "2023-03-10" },
    { id: 4, name: "Francesca Neri", email: "francesca.neri@email.com", phone: "+39 456 789 0123", status: "Attivo", registrationDate: "2023-04-05" },
    { id: 5, name: "Alessandro Gialli", email: "alessandro.gialli@email.com", phone: "+39 567 890 1234", status: "Inattivo", registrationDate: "2023-05-22" },
    { id: 6, name: "Valentina Blu", email: "valentina.blu@email.com", phone: "+39 678 901 2345", status: "Attivo", registrationDate: "2023-06-18" },
    { id: 7, name: "Davide Arancio", email: "davide.arancio@email.com", phone: "+39 789 012 3456", status: "Attivo", registrationDate: "2023-07-30" },
    { id: 8, name: "Elisa Viola", email: "elisa.viola@email.com", phone: "+39 890 123 4567", status: "Inattivo", registrationDate: "2023-08-12" },
    { id: 9, name: "Roberto Marrone", email: "roberto.marrone@email.com", phone: "+39 901 234 5678", status: "Attivo", registrationDate: "2023-09-25" },
    { id: 10, name: "Chiara Azzurra", email: "chiara.azzurra@email.com", phone: "+39 012 345 6789", status: "Attivo", registrationDate: "2023-10-07" },
    { id: 11, name: "Fabio Grigio", email: "fabio.grigio@email.com", phone: "+39 123 456 7891", status: "Inattivo", registrationDate: "2023-11-19" },
    { id: 12, name: "Sara Rosa", email: "sara.rosa@email.com", phone: "+39 234 567 8902", status: "Attivo", registrationDate: "2023-12-01" },
    { id: 13, name: "Marco Celeste", email: "marco.celeste@email.com", phone: "+39 345 678 9013", status: "Attivo", registrationDate: "2024-01-14" },
    { id: 14, name: "Laura Indaco", email: "laura.indaco@email.com", phone: "+39 456 789 0124", status: "Inattivo", registrationDate: "2024-02-26" },
    { id: 15, name: "Andrea Turchese", email: "andrea.turchese@email.com", phone: "+39 567 890 1235", status: "Attivo", registrationDate: "2024-03-09" },
    { id: 16, name: "Simona Magenta", email: "simona.magenta@email.com", phone: "+39 678 901 2346", status: "Attivo", registrationDate: "2024-04-21" },
    { id: 17, name: "Paolo Ocra", email: "paolo.ocra@email.com", phone: "+39 789 012 3457", status: "Inattivo", registrationDate: "2024-05-03" },
    { id: 18, name: "Elena Beige", email: "elena.beige@email.com", phone: "+39 890 123 4568", status: "Attivo", registrationDate: "2024-06-15" },
    { id: 19, name: "Giorgio Oro", email: "giorgio.oro@email.com", phone: "+39 901 234 5679", status: "Attivo", registrationDate: "2024-07-27" },
    { id: 20, name: "Cristina Argento", email: "cristina.argento@email.com", phone: "+39 012 345 6780", status: "Inattivo", registrationDate: "2024-08-08" },
];

