import mockApiGraficoStatoDomini from '../../CommonData/mockApiResponse/mockApiGraficoStatoDomini.json';


export const GraficoStatoDomini = async () => {
    try {

        const response = await new Promise(resolve => setTimeout(() => resolve(mockApiGraficoStatoDomini), 500));

        if (response.status ==='success' && Array.isArray(response.data.overview)){
            return response.data.overview;
        } else {
            throw new Error('Formato dei dati non valido o vuoti');
        }

    } catch (error) {
        console.error('Errore nel recupero dati:', error);
        return [];
    }
}

