import React, {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";

import {columnAreaOptions} from "./Options/LineColumnAreaOptions";
import {GraficoStatoDomini} from "../../../CommonData/Dashboard/lineColumnAreaData";
import {Alert, Card, CardBody, Spinner} from "reactstrap";


const LineColumnArea = () => {
    const [linecolumnAreaSeries, setLineColumnAreaSeries] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const data = await GraficoStatoDomini();


                if (Array.isArray(data) && data.length > 0) {
                    setLineColumnAreaSeries(data)
                } else {
                    throw new Error("Dati non validi o vuoti");
                }
                setError(null);
            } catch (err) {
                console.error("Error fetching renewal notifications:", err);
                setError("Errore nel caricamento dei dati");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [])

    const renderContent = () => {
        if (loading) {
            return (
                <div className="text-center"
                     style={{minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div>
                        <Spinner color="primary" style={{width: '3rem', height: '3rem'}}/>
                        <p className="mt-2">Caricamento in corso...</p>
                    </div>
                </div>
            );
        }

        if (error || linecolumnAreaSeries.length === 0) {
            return (
                <Alert color="danger">
                    Errore nel caricamento dei dati. Si prega di contattare l'amministratore o riprovare più tardi.
                </Alert>
            );
        }

        return (
            <React.Fragment>
                <ReactApexChart
                    options={columnAreaOptions}
                    series={linecolumnAreaSeries}
                    type="line"
                    height="350"
                    className="apex-charts"
                />
            </React.Fragment>
        )
    };

    return (
        <Card>
            <CardBody>
                {renderContent()}
            </CardBody>
        </Card>
    );
};

export default LineColumnArea;
