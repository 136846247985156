

export const ListaDomini = [
    { id: 1, name: 'example.com', expirationDate: '2024-12-31', status: 'Scaduto', registrar: 'Registrar A', nameservers: 'ns1.example.com, ns2.example.com' },
    { id: 2, name: 'mysite.org', expirationDate: '2024-10-15', status: 'Attivo', registrar: 'Registrar B', nameservers: 'ns1.mysite.org, ns2.mysite.org' },
    { id: 3, name: 'testdomain.net', expirationDate: '2024-08-01', status: 'In scadenza', registrar: 'Registrar C', nameservers: 'ns1.testdomain.net, ns2.testdomain.net' },
    { id: 4, name: 'newproject.io', expirationDate: '2025-03-20', status: 'Scaduto', registrar: 'Registrar A', nameservers: 'ns1.newproject.io, ns2.newproject.io' },
    { id: 5, name: 'yourbusiness.com', expirationDate: '2024-11-30', status: 'Attivo', registrar: 'Registrar B', nameservers: 'ns1.yourbusiness.com, ns2.yourbusiness.com' },
    { id: 6, name: 'techblog.dev', expirationDate: '2024-09-15', status: 'In scadenza', registrar: 'Registrar C', nameservers: 'ns1.techblog.dev, ns2.techblog.dev' },
    { id: 7, name: 'ecommerce-site.store', expirationDate: '2025-01-10', status: 'Attivo', registrar: 'Registrar A', nameservers: 'ns1.ecommerce-site.store, ns2.ecommerce-site.store' },
    { id: 8, name: 'portfolio.design', expirationDate: '2024-07-22', status: 'In scadenza', registrar: 'Registrar B', nameservers: 'ns1.portfolio.design, ns2.portfolio.design' },
    { id: 9, name: 'startup-name.co', expirationDate: '2025-05-01', status: 'Scaduto', registrar: 'Registrar C', nameservers: 'ns1.startup-name.co, ns2.startup-name.co' },
    { id: 10, name: 'local-business.it', expirationDate: '2024-12-05', status: 'Attivo', registrar: 'Registrar A', nameservers: 'ns1.local-business.it, ns2.local-business.it' },
    { id: 11, name: 'onlineshop.com', expirationDate: '2023-11-30', status: 'Scaduto', registrar: 'Registrar D', nameservers: 'ns1.onlineshop.com, ns2.onlineshop.com' },
    { id: 12, name: 'tech-news.net', expirationDate: '2025-02-28', status: 'Attivo', registrar: 'Registrar E', nameservers: 'ns1.tech-news.net, ns2.tech-news.net' },
    { id: 13, name: 'fashionblog.com', expirationDate: '2024-06-15', status: 'In scadenza', registrar: 'Registrar B', nameservers: 'ns1.fashionblog.com, ns2.fashionblog.com' },
    { id: 14, name: 'gamer-hub.gg', expirationDate: '2023-10-01', status: 'Scaduto', registrar: 'Registrar F', nameservers: 'ns1.gamer-hub.gg, ns2.gamer-hub.gg' },
    { id: 15, name: 'fitness-coach.fit', expirationDate: '2025-04-30', status: 'Attivo', registrar: 'Registrar A', nameservers: 'ns1.fitness-coach.fit, ns2.fitness-coach.fit' },
    { id: 16, name: 'recipe-blog.cooking', expirationDate: '2024-08-31', status: 'Attivo', registrar: 'Registrar C', nameservers: 'ns1.recipe-blog.cooking, ns2.recipe-blog.cooking' },
    { id: 17, name: 'travel-photos.pics', expirationDate: '2023-12-15', status: 'Scaduto', registrar: 'Registrar D', nameservers: 'ns1.travel-photos.pics, ns2.travel-photos.pics' },
    { id: 18, name: 'learn-code.edu', expirationDate: '2024-11-01', status: 'In scadenza', registrar: 'Registrar E', nameservers: 'ns1.learn-code.edu, ns2.learn-code.edu' },
    { id: 19, name: 'crypto-news.finance', expirationDate: '2025-01-31', status: 'Attivo', registrar: 'Registrar F', nameservers: 'ns1.crypto-news.finance, ns2.crypto-news.finance' },
    { id: 20, name: 'pet-supplies.shop', expirationDate: '2023-09-30', status: 'Scaduto', registrar: 'Registrar B', nameservers: 'ns1.pet-supplies.shop, ns2.pet-supplies.shop' },
];
