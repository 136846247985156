import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import SimpleBar from "simplebar-react";
import {Alert, Card, CardBody, CardTitle, Spinner} from "reactstrap";
import {NotificationsData} from "../../../CommonData/Dashboard/NotificheRinnovi";

const RenewalNotifications = () => {

    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await NotificationsData();
                if (Array.isArray(data) && data.length > 0) {
                    setNotifications(data);
                } else {
                    throw new Error("Dati non validi o vuoti");
                }
                setError(null);
            } catch (err) {
                console.error("Error fetching renewal notifications:", err);
                setError("Errore nel caricamento dei dati");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const renderContent = () => {
        if (loading) {
            return (
                <div className="text-center"
                     style={{minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div>
                        <Spinner color="primary" style={{width: '3rem', height: '3rem'}}/>
                        <p className="mt-2">Caricamento in corso...</p>
                    </div>
                </div>
            );
        }

        if (error || notifications.length === 0) {
            return (
                <Alert color="danger">
                    Errore nel caricamento dei dati. Si prega di contattare l'amministratore o riprovare più tardi.
                </Alert>
            );
        }

        return (
            <>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">Notifiche rinnovi</CardTitle>
                        <SimpleBar style={{maxHeight: "287px"}}>
                            {notifications.map((item, key) => (
                                <Link key={key} to="#" className="text-body d-block">
                                    <div className="d-flex py-3">
                                        <div className="flex-shrink-0 me-3 align-self-center">
                                            <div className="avatar-xs">
                    <span className={`avatar-title rounded-circle ${item.bgColor}`}>
                      <i className={item.icon}></i>
                    </span>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h5 className="font-size-14 mb-1">{item.name}</h5>
                                            <p className="text-truncate mb-0">{item.desc}</p>
                                        </div>
                                        <div className="flex-shrink-0 font-size-13">
                                            {item.time}
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </SimpleBar>
                    </CardBody>
                </Card>
            </>
        );
    };
    return (
        <Card>
            <CardBody>
                {renderContent()}
            </CardBody>
        </Card>
    );
};

export default RenewalNotifications;
