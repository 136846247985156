import React from "react";
import Routes from "./Routes/index";

// Import Scss
import './assets/scss/theme.scss';
import {AuthProvider} from "./contexts/AuthContext";

function App() {
    return (
        <AuthProvider>
                <React.Fragment>
                    <Routes />
                </React.Fragment>
        </AuthProvider>
    );
}

export default App;
